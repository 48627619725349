@media (min-width: 1440px) {
  h2.page-name {
    text-align: center;
    background-color: #a9a9a9;
    margin: 2% auto;
    padding: 10px 0;
    font-size: 3.4rem;
    font-weight: normal;
  }

  h3.beginner-title, h3.why-is-pcgame {
    text-align: center;
    margin-bottom: 0;
    font-size: 2.8rem;
    font-weight: normal;
  }

  p.summary {
    font-size: 1.6rem;
    font-weight: normal;
  }

  div.advantage-wrapper {
    justify-content: space-evenly;
    align-items: unset;
    flex-flow: row;
    margin: 0 auto 5%;
    display: flex;
  }

  div.advantage-wrapper div.pc-advantage-box, div.advantage-wrapper div.consumer-advantage-box {
    width: 45%;
    height: auto;
    align-self: stretch;
  }

  div.advantage-wrapper div.pc-advantage-box h4.advantage-title, div.advantage-wrapper div.consumer-advantage-box h4.advantage-title {
    text-align: center;
    color: #0084ff;
    margin: auto;
    font-family: normal;
    font-size: 2.2rem;
  }

  div.advantage-wrapper div.pc-advantage-box div.advantage-detail, div.advantage-wrapper div.consumer-advantage-box div.advantage-detail {
    width: 100%;
    height: 420px;
    border: 5px dotted #a9a9a9;
    border-radius: 20px;
    padding: 10px;
  }

  div.advantage-wrapper div.pc-advantage-box div.advantage-detail ul.advantage-list li.advantage-item, div.advantage-wrapper div.consumer-advantage-box div.advantage-detail ul.advantage-list li.advantage-item {
    font-size: 1.4rem;
    list-style-type: circle;
  }
}

/*# sourceMappingURL=index.8899ffdf.css.map */
