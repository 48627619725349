@media all and (max-width: 767px) {

}


@media all and (min-width: 768px) and (max-width: 1439px) {

}


@media all and (min-width: 1440px) {

h2.page-name {
  background-color: #a9a9a9;

  margin: 2% auto;
  padding: 10px 0;
  text-align: center;

  font-size: 3.4rem;
  font-weight: normal;
}

h3.beginner-title, h3.why-is-pcgame {
  margin-bottom: 0;
  text-align: center;

  font-size: 2.8rem;
  font-weight: normal;
}

p.summary {
  font-size: 1.6rem;
  font-weight: normal;
}

div.advantage-wrapper {
  margin: 0 auto 5%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: unset;
  div.pc-advantage-box,
  div.consumer-advantage-box {
    align-self: stretch;

    width: 45%;
    height: auto;
    h4.advantage-title {
      margin: auto;
      text-align: center;

      color: #0084ff;
      font-size: 2.2rem;
      font-family: normal;
    }
    div.advantage-detail {
      border: 5px dotted #a9a9a9;
      border-radius: 20px;

      width: 100%;
      height: 420px;

      padding: 10px;
      ul.advantage-list {
        li.advantage-item {
          list-style-type: circle;

          font-size: 1.4rem;
        }
      }
    }
  }
}

}
